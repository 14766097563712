import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'solar:widget-add-line-duotone',
    route: '/dashboard',
    roles:[ 'Admin','Store'], 
    
  },
  {
    displayName: 'Leads',
    iconName: 'solar:user-circle-line-duotone',
    route: '/leads',
    roles:[ 'Admin','Store'], 
    
  },
  {
    displayName: 'Leads Type',
    iconName: 'solar:document-line-duotone',
    route: '/leads-type',
    roles:[ 'Admin'], 
  },
  {
    displayName: 'Leaves',
    iconName: 'solar:calendar-line-duotone',
    route: '/leaves',
    roles: [ 'Admin'], 
  },
  {
    displayName: 'Domain Email',
    iconName: 'solar:letter-line-duotone',
    route: '/domain-email',
    roles: [ 'Admin'], 
  },
  {
    displayName: 'Stores',
    iconName: 'solar:shop-line-duotone',
    route: '/stores',
    roles: [ 'Admin'], 
  },
  {
    displayName: 'Representative',
    iconName: 'solar:user-circle-line-duotone',
    route: '/sales-representative',
    roles: [ 'Admin'], 
  },

];

export const productionItem: NavItem[] = [
   {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'solar:widget-add-line-duotone',
    route: '/dashboard',
    roles:[ 'all'], 
  },
  {
    displayName: 'Leads',
    iconName: 'solar:user-circle-line-duotone',
    route: '/leads',
    roles:[ 'all'], 
    
  },
  {
    displayName: 'Leads Type',
    iconName: 'solar:document-line-duotone',
    route: '/leads-type',
    roles: [ 'Admin'], 
  },
  {
    displayName: 'Leaves',
    iconName: 'solar:calendar-line-duotone',
    route: '/leaves',
    roles: [ 'Admin'], 
  },
  {
    displayName: 'Domain Email',
    iconName: 'solar:letter-line-duotone',
    route: '/domain-email',
    roles: [ 'Admin'], 
  },
  {
    displayName: 'Stores',
    iconName: 'solar:shop-line-duotone',
    route: '/stores',
    roles: [ 'Admin'], 
  },

];
