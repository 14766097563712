import { inject } from '@angular/core';
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
export const canDeactivateGuard: CanActivateFn = (route, state) => {

  let authService = inject(AuthService);
  const router = inject(Router); // Inject the Router to use for redirection
  if (authService.IsAuthenticated() != true) {
    return true;
  }
  else {
    return router.createUrlTree(['/dashboard']);
  }
};
