import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private _snackBar: MatSnackBar) { }

    public error(message: string, action?: string, duration?: number) {
        this._snackBar.open(message, action,
            {
                duration: duration ?? 3000,
            });
    }

    public success(message: string, action?: string, duration?: number) {
        this._snackBar.open(message, action,
            {
                duration: duration ?? 3000,
            });
    }

    public added(message: string, action?: string) {
        this._snackBar.open(message, action,
            {
                duration: 3000,
            });
    }
}
