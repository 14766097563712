<mat-toolbar class="topbar">
  <!-- Mobile Menu -->
  @if(!showToggle) {
  }
  <button mat-mini-fab color="inherit" (click)="toggleMobileNav.emit()" class="d-flex justify-content-center">
    <i class="iconify icon-32 d-flex" data-icon="solar:hamburger-menu-line-duotone"></i>
  </button>

  <span class="flex-1-auto"></span>


  <!-- --------------------------------------------------------------- -->
  <!-- Notifications Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <button mat-fab extended color="inherit" aria-label="Notifications" [matMenuTriggerFor]="notificationsmenu"
    class="d-flex justify-content-center profile-btn-dd">
    <app-feather-icons [icon]="'bell'" [class]="'tbl-fav-bell'"></app-feather-icons>
    <!-- Badge for unread notifications -->
    <span matBadge="5" matBadgeColor="accent" class="notification-badge"></span>
  </button>
  <mat-menu #notificationsmenu="matMenu" class="topbar-dd cardWithShadow">
    <!-- Sample Notification 1 -->
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"><app-feather-icons class="d-flex align-items-center" [icon]="'shield'"
          [class]="'tbl-fav-back'"></app-feather-icons></mat-icon>
      <span><strong>Admin</strong> has 3 leads assigned</span>
    </button>

    <!-- Sample Notification 2: Sales rep exceeds limit -->
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"><app-feather-icons class="d-flex align-items-center"
          [icon]="'alert-triangle'" [class]="'tbl-fav-back'"></app-feather-icons></mat-icon>
      <span><strong>Sales Representative</strong> has exceeded the limit for xyz store</span>
    </button>

    <!-- Sample Notification 3 -->
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"><app-feather-icons class="d-flex align-items-center"
          [icon]="'message-circle'" [class]="'tbl-fav-back'"></app-feather-icons></mat-icon>
      <span>You received a new message</span>
    </button>

    <!-- Divider (optional) -->
    <mat-divider></mat-divider>

    <!-- View All Notifications Button -->
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"><app-feather-icons class="d-flex align-items-center"
          [icon]="'chevron-down'" [class]="'tbl-fav-back'"></app-feather-icons></mat-icon>
      <span>View all notifications</span>
    </button>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <button mat-fab extended color="inherit" [matMenuTriggerFor]="profilemenu" aria-label="Notifications"
    class="d-flex justify-content-center profile-btn-dd">
    <img src="/assets/images/profile/user-1.jpg" class="rounded-circle object-cover icon-35 profile-dd" width="35" />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"><i data-icon="solar:shield-user-line-duotone"
          class="iconify icon-22"></i></mat-icon>My Profile
    </button>
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"><i data-icon="solar:user-id-line-duotone" class="iconify icon-22"></i>
      </mat-icon>My Account
    </button>
    <!-- <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"><i data-icon="solar:checklist-minimalistic-line-duotone"
          class="iconify icon-22"></i> </mat-icon>My Task
    </button> -->

    <div class="p-x-12 m-t-12">
      <a (click)="authService.logout()" mat-stroked-button color="primary" class="w-100">Logout</a>
    </div>
  </mat-menu>

</mat-toolbar>