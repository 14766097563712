<mat-sidenav-container class="mainWrapper blue_theme light-theme" autosize autoFocus dir="ltr">
  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->

  <mat-sidenav #leftsidenav [mode]="isOver ? 'over' : 'side'" [opened]="!isOver"
    (openedChange)="onSidenavOpenedChange($event)" (closedStart)="onSidenavClosedStart()" class="sidebarNav">
    <div class="flex-layout">
      <app-sidebar (toggleMobileNav)="sidenav.toggle()"></app-sidebar>
      <ng-scrollbar class="position-relative" style="height: 100%">
        @if(isProduction ){
       
        <mat-nav-list class="sidebar-list" >
          @for(item of navProdItems; track item) {
            @if(item.roles != userRole){

              <app-nav-item [item]="item" (notify)="sidenav.toggle()">
              </app-nav-item>
            }
          }
        </mat-nav-list>

        } @else{
        <mat-nav-list class="sidebar-list">
          @for(item of navItems; track item) {
            @if(!item.roles || (item.roles.includes(userRole) || item.roles.includes('all'))){
              <app-nav-item [item]="item" (notify)="sidenav.toggle()">
              </app-nav-item>
            }
          }
        </mat-nav-list>
        }

      </ng-scrollbar>

    </div>
  </mat-sidenav>

  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper" #content>
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->

    <app-header [showToggle]="!isOver" (toggleCollapsed)="toggleCollapsed()" (toggleMobileNav)="sidenav.toggle()">
    </app-header>

    <div class="body-wrapper">
      <!-- maxWidth -->
      <main class="pageWrapper ">
        <!-- ============================================================== -->
        <!-- Outlet -->
        <!-- ============================================================== -->
        <router-outlet></router-outlet>
      </main>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
