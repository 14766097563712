import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';
import { ApiConfigService } from '../common/apiConfig.service';

@Injectable({
  providedIn: 'root'
})
export class LeavesService {
  constructor(private http: HttpService) {
  
  }
  leaveList(requestModel:any) { 
    return this.http.postApi(ApiConfigService.endpoints.leaveListApi,requestModel);
  }
  getLeaveById(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.getLeavesApi,requestModel);
  }
  updateLeaveById(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.updateLeavesApi,requestModel);
  }
  addLeave(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.applyLeavesApi,requestModel );
  }
  cancelLeave(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.cancelLeavesApi,requestModel );
  }
}
