// const API_PATH_Uat = 'http://localhost:3000/api/';
const API_PATH_Uat = 'https://tricoastbackend.24livehost.com/api/';
// const API_PATH_Uat = 'http://localhost:3000/api/';
const API_VERSION ='v1/';

export const environment = {
  production: false,
  env: 'dev',
  API_BASE_URL: API_PATH_Uat+API_VERSION,

};
