import { Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './guards/auth.guard';
import { canDeactivateGuard } from './guards/can-deactivate.guard';
import { RoleGuard } from './guards/role.guard';

export const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full',
      },
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.routes').then(
            (m) => m.AuthenticationRoutes
          ),
        canActivate: [canDeactivateGuard]
      },
    ],
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/pages.routes').then((m) => m.PagesRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'leads',
        loadChildren: () =>
          import('./components/leads/leads.routes').then((m) => m.LeadsRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'leads-type',
        loadChildren: () =>
          import('./components/leads-type/leads-type.routes').then((m) => m.LeadsTypeRoutes),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ['Admin'] },
      },
      {
        path: 'leaves',
        loadChildren: () =>
          import('./components/leaves/leaves.routes').then((m) => m.LeavesRoutes),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ['Admin'] },
      },
      {
        path: 'domain-email',
        loadChildren: () =>
          import('./components/email-domain/email-domain.routes').then((m) => m.EmailDomainRoutes),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ['Admin'] },
      },
      {
        path: 'stores',
        loadChildren: () =>
          import('./components/stores/stores.routes').then((m) => m.StoreRoutes),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ['Admin'] },
      },
      {
        path: 'sales-representative',
        loadChildren: () =>
          import('./components/sales-representative/sales-representative.routes').then((m) => m.SalesRepresentativeRoutes),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ['Admin'] },
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];
