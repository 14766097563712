import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';

@Injectable({ providedIn: 'root' })
export class ApiConfigService {

  private static BASE_URL = environment.API_BASE_URL;
  // private static BASE_URL = 'http://192.168.6.59:2000/api/v1/'

  private readonly headers = {
    'Content-Type': 'application/json',
  };

  public static  endpoints = {
    login: `${ApiConfigService.BASE_URL}auth/login`,
    register: `${ApiConfigService.BASE_URL}auth/register`,
    verifyOtp: `${ApiConfigService.BASE_URL}auth/verify-otp`,
    forgotPassword: `${ApiConfigService.BASE_URL}auth/forgot-password`,
    resetPasswordOtpVerify: `${ApiConfigService.BASE_URL}auth/reset-password`,
    leadListApi: `${ApiConfigService.BASE_URL}leads`,
    leadsDetails: `${ApiConfigService.BASE_URL}leads/leadsDetails`,
    applyLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type`,
    getLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type/lists`,
    updateLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type/update`,
    deleteLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type/delete`,
    leaveListApi: `${ApiConfigService.BASE_URL}leaves`,
    getLeavesApi: `${ApiConfigService.BASE_URL}leaves/leavesbyid`,
    updateLeavesApi: `${ApiConfigService.BASE_URL}leaves/update`,
    applyLeavesApi: `${ApiConfigService.BASE_URL}leaves/apply`,
    cancelLeavesApi: `${ApiConfigService.BASE_URL}leaves/cancel`,
    emailDomainListApi: `${ApiConfigService.BASE_URL}email-domain-list`,
    applyDomainApi: `${ApiConfigService.BASE_URL}domains`,
    getDomainListApi: `${ApiConfigService.BASE_URL}domains/lists`,
    getDomainDataApi: `${ApiConfigService.BASE_URL}domains/listById`,
    updateDomainApi: `${ApiConfigService.BASE_URL}domains/update`,
    deleteDomainApi: `${ApiConfigService.BASE_URL}domains/delete`,
    representatives: `${ApiConfigService.BASE_URL}leaves/representatives`,
    getStoresListApi: `${ApiConfigService.BASE_URL}store/lists`,
    getStoresDataApi: `${ApiConfigService.BASE_URL}store/listsById`,
    createStoresApi: `${ApiConfigService.BASE_URL}store`,
    updateStoreApi: `${ApiConfigService.BASE_URL}store/update`,
    deleteStoreApi: `${ApiConfigService.BASE_URL}store/delete`,
    getAssignmentListApi: `${ApiConfigService.BASE_URL}store/representatives`,
    createAssignmentApi: `${ApiConfigService.BASE_URL}assignment`,
    getRepresentativeListApi: `${ApiConfigService.BASE_URL}representative/lists`,
    getRepresentativeDataApi: `${ApiConfigService.BASE_URL}representative/listById`,
    createRepresentativeApi: `${ApiConfigService.BASE_URL}representative`,
    updateRepresentativeApi: `${ApiConfigService.BASE_URL}representative/update`,
    deleteRepresentativeApi: `${ApiConfigService.BASE_URL}representative/delete`,
    representativeDetails: `${ApiConfigService.BASE_URL}representative/representativeDetails`,
  };

  public getHeaders(): { [key: string]: string } {
    return this.headers;
  }

  public getEndpoint(key: keyof typeof ApiConfigService.endpoints): string {
    return ApiConfigService.endpoints[key];
  }
}
