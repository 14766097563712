<!-- @if(item.navCap) {
@if(item.divider) {
<span class="sidebar-divider"></span>
}
<div mat-subheader class="nav-caption">
  {{ item.navCap }}
</div>
} @else {
<a mat-list-item  (click)="onItemSelected(item)"  [ngClass]="{
      'mat-toolbar mat-primary activeMenu': item.route.includes(item.route)
        ? router.isActive(item.route, true)
        : false,
    }" class="menu-list-item">
  <span class="iconify routeIcon" matListItemIcon [attr.data-icon]="item.iconName"></span>

  <span class="hide-menu">{{ item.displayName }}</span>
</a>
} -->

<div *ngIf="item.navCap">
  <ng-container *ngIf="item.divider">
    <span class="sidebar-divider"></span>
  </ng-container>

  <div mat-subheader class="nav-caption">
    {{ item.navCap }}
  </div>
</div>

<ng-container *ngIf="!item.navCap">
  <a mat-list-item (click)="onItemSelected(item)" [ngClass]="{
    'mat-toolbar mat-primary activeMenu': isActive(item)
  }" class="menu-list-item">
    <span class="iconify routeIcon" matListItemIcon [attr.data-icon]="item.iconName"></span>
    <span class="hide-menu">{{ item.displayName }}</span>
  </a>

</ng-container>