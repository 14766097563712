import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

import { finalize, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoaderService } from '../loader-service/loader.service';
import { LeavesService } from '../leaves-service/leaves.service';

@Injectable()

export class LoaderInterceptor implements HttpInterceptor {
  private activeRequests = 0;

  public constructor(
    private leaveService: LeavesService,
    private readonly _loaderService: LoaderService
  ) {  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiUrl = req.url.split('/')
    let endPoint = apiUrl[apiUrl.length -1]
    
    if (this.activeRequests == 0 && endPoint != 'representatives') {
      this._loaderService.show();
  
    this.activeRequests++;
  }

    return next.handle(req).pipe(
      finalize(() => {
        this.stopLoader();
      }),
    );
  }

  private stopLoader() {
    this.activeRequests--;
    if (this.activeRequests == 0) {
      this._loaderService.hide();
    }
  }
}